<template>
  <div class="easyscreen-video">
    <easyscreen-youtube
      v-if="isYoutube"

      :url="src"
      :muted="muted"
      :autoplay="autoplay"
      :startTime="startTime"
      :overlay="overlay"
      :volume="volume"
      :image="image"
      :credit="credit"
      :description="description"
      :poster="poster"
    />
    <video-native
      v-else-if="isNativeVideo"

      :url="src"
      :muted="muted"
      :autoplay="autoplay"
      :startTime="startTime"
      :overlay="overlay"
      :volume="volume"
      :image="image"
      :credit="credit"
      :description="description"
      :poster="poster"
    />
    <div
      v-else
    >
      Usupported video format
    </div>
  </div>
</template>

<style src="./video.less" lang="less"></style>

<script>
  import EasyscreenYoutube from "../youtube/youtube.vue";
  import VideoNative from "./video-native.vue";

  export default {
    name: "easyscreen-video",
    props: {
      /* The url of html5 or youtube video. */
      src: String,
      /* The mute option of video (will be applied if possible). */
      muted: {
        type: Boolean,
        default: false
      },
      /* Enables autoplay. */
      autoplay: {
        type: Boolean,
        default: true
      },
      /* The time from which the video should be started. */
      startTime: {
        type: Number,
        default: 0
      },
      /* Flag for draw the overlay which prevents any interaction. */
      overlay: {
        type: Boolean,
        default: true
      },
      /* The default volume of video (video will be muted with autoplay). */
      volume: [String, Number],
      /* The poster image. The autoplay should be disabled for use. */
      image: String,
      /* The credit of the video. */
      credit: String,
      /* The video description. The autoplay should be disabled for use. */
      description: String,
      /* The type of video poster. See the available options for ./video-poster.vue. */
      poster: String
    },
    computed: {
      isYoutube: {
        get() {
          return Boolean(this.src) && !this.isNativeVideo;
        }
      },
      isNativeVideo: {
        get() {
          return Boolean(this.src) && this.src.indexOf("web/videos") !== -1 || /\.mp4$/.test(this.src);
        }
      }
    },
    components: {
      "easyscreen-youtube": EasyscreenYoutube,
      "video-native": VideoNative
    }
  };
</script>
