<template>
  <modal-layout-navigation
    ref="screenNavigation"
    :title="$easyscreenConfig.get('library.name', $easyscreenConfig.name)"
  >
    <div
      :class="['welcome-screen-light', orientation]"
      :style="{ '--special-color': specialColor }"
    >
      <div class="welcome-screen-light--navigation">
        <welcome-screen-tile-light
          v-for="tile in activeTiles"
          :class="[
            'welcome-screen-light--navigation-element',
            'welcome-screen-light--navigation-element_size-' + _get(tile, 'tileOptions.size', 1),
            { 'welcome-screen-light--navigation-element_disabled': _get(tile, 'tileData.type') === 'no-events' }
          ]"
          :key="`tile-${ tile.key }`"
          :color="_get(tile, 'tileOptions.color')"
          :title="_get(tile, 'tileData.options.title')"
          :background="_get(tile, 'tileData.customBg') || _get(tile, 'tileData.options.image')"
          :active="selectedTile === tile"
          @click.native="_get(tile, 'tileData.type') !== 'node-list' ? selectTile(tile) : null"
        >
          <loader
            v-if="tile.loading"
            type="in-place"
            size="medium"
          />
          <node-list-tile-content
            v-else-if="_get(tile, 'tileData.type') === 'node-list'"
            design="light"
            :title="_get(tile, 'tileOptions.title')"
            :elements="_get(tile, 'tileData.options.items')"
            :color="_get(tile, 'tileOptions.color')"
            :sub-type="_get(tile, 'tileData.options.subType')"
            :amount-of-elements="_get(tile, 'tileOptions.meta.nodeList.amountOfElements')"
            @open-tile="selectTile(tile)"
            @open-element="(element) => _openNodelistElement(tile, element)"
          />
          <div
            v-if="_get(tile, 'tileData.type') === 'no-events'"
            class="welcome-screen-light--no-data"
          >
            <h2 class="welcome-screen-light--no-data-placeholder" >{{ $l10n("No upcoming events!") }}</h2>
          </div>
        </welcome-screen-tile-light>
      </div>
      <div class="welcome-screen-light--content" :data-tile-type="_get(selectedTile, 'tileData.type')">
        <div class="welcome-screen-light--content-wrapper">
          <welcome-screen-overview-light
            ref="overview"
            class="welcome-screen-light--overview"
            @push-screen="(closeScreen) => $refs.screenNavigation.pushBackAction(closeScreen, 'close-screen')"
          >
            <!-- :smooth-edge-color="specialColorWithOverlay" -->
            <!-- TODO: Add the manual transition for special color, because the css linear gradient can't be animated with transition rule. -->
            <scrollable
              max-height="100%"
            >
              <div class="welcome-screen-light--welcome-content">
                <h1 v-if="welcomeVideoTitle" class="welcome-screen-light--welcome-title">
                  {{ welcomeVideoTitle }}
                </h1>

                <easyscreen-video
                  v-if="activeSlide.welcomeVideo"
                  class="welcome-screen-light--welcome-video"
                  :src="activeSlide.welcomeVideo"
                />

                <img
                  v-if="activeSlide.welcomeImage"
                  class="welcome-screen-light--welcome-image"
                  :src="activeSlide.welcomeImage"
                />

                <p v-if="activeSlide.welcomeScreenText" class="welcome-screen-light--welcome-text">
                  {{ activeSlide.welcomeScreenText }}
                </p>
              </div>
            </scrollable>
          </welcome-screen-overview-light>
        </div>
      </div>
    </div>

    <template slot="footer-right">
      <img
        v-if="$easyscreenSkin.isRclbib"
        class="welcome-screen-light--sponsors-logo"
        src="/skins/rclbib/welcome-screen-footer-logos.png" alt="RCL library sponsors logo"
      />
    </template>
  </modal-layout-navigation>
</template>

<style src="./welcome-screen-light.less" lang="less"></style>

<script>
  import lodash from "lodash";
  import ColorMixer from "color-mixer";
  import { normal as normalColor } from "color-blend";

  import Loader from "../core/loader/loader.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import EasyscreenVideo from "../core/video/video.vue";
  import ModalLayoutNavigation from "../core/modal/layout-navigation.vue";
  import NodeListTileContent from "../welcome-screen/welcome-screen-tile-content/node-list-tile-content.vue";
  import welcomeScreenClassic from "../welcome-screen/welcome-screen-classic.vue";
  import WelcomeScreenOverviewLight from "./welcome-screen-overview-light.vue";
  import welcomeScreenTileLight from "./welcome-screen-tile-light.vue";

  const defaultSpecialColor = "#4D898E";
  const themeDataWrapperBackgroundColor = { r: 0, g: 0, b: 0, a: 0.2 };

  export default {
    name: "welcome-screen-light",
    mixins: [welcomeScreenClassic],
    props: {
      slides: {
        type: Array,
        default: () => ([])
      }
    },
    computed: {
      activeTiles() {
        const tiles = welcomeScreenClassic.computed.activeTiles.get.call(this).map((tile) => {
          const isCustomBackgroundColorEnabled = lodash.get(tile, "tileData.options.slideSettings.settings.backgroundColorEnabled");
          if (isCustomBackgroundColorEnabled)
            tile.tileOptions.color = lodash.get(tile, "tileData.options.slideSettings.settings.backgroundColor");

          return tile;
        });

        /* Temporary solution: Move the tile with events to the top. */
        return tiles.slice().sort((a, b) => {
          if (a.tileData && a.tileData.type === "node-list")
            return -1;

          if (b.tileData && b.tileData.type === "node-list")
            return 1;

          return 0;
        }).map(tile => {
          if (tile.tileData && tile.tileData.type === "node-list") {
            tile.tileOptions.size = 2;

            if (lodash.get(tile, "tileData.options.items").length === 0) {
              tile = Object.assign({}, tile);
              tile.tileData = Object.assign({}, tile.tileData, { type: "no-events" });
            }
          }

          return tile;
        }).slice(0, 7);
      },
      specialColor() {
        if (!this.selectedTile)
          return lodash.get(this.activeSlide, "options.defaultColor") || defaultSpecialColor;

        // Specified by task ES-2411
        if (this.$easyscreenSkin.isRclbib && lodash.get(this.selectedTile, "tileData.type") === "node-list")
          return "#ffffff";

        return lodash.get(this.selectedTile, "tileOptions.color");
      },
      specialColorWithOverlay() {
        //Specified by task ES-2411
        if (this.$easyscreenSkin.isRclbib && lodash.get(this.selectedTile, "tileData.type") === "node-list")
          return "#ffffff";

        let specialColor = new ColorMixer.Color({ hex: this.specialColor }).rgb();
        specialColor = { r: specialColor[0], g: specialColor[1], b: specialColor[2], a: 1 };

        const mixedSpecialColor = normalColor(specialColor, themeDataWrapperBackgroundColor);
        return new ColorMixer.Color({ rgb: [mixedSpecialColor.r, mixedSpecialColor.g, mixedSpecialColor.b] }).hex();
      }
    },
    data() {
      return {
        selectedTile: null
      };
    },
    methods: {
      selectTile(tile) {
        const content = tile.tileOptions.links && tile.tileOptions.links[0];
        if (!content)
          return console.warn("No content to show for current tile!", tile);

        this.selectedTile = tile;

        let isFullscreen = content.slides.widgets.length === 1 && content.slides.widgets[0].contentType === "FullScreenCT";
        this.$refs.overview.replaceScreensGroup({
          id: tile.key,
          isFullscreen: isFullscreen,
          color: lodash.get(tile, "tileOptions.color"),
          content: {
            data: content,
            type: "generic",
            links: tile.tileOptions.links.filter(link => link !== content)
          }
        });

        this.$refs.screenNavigation.removeBackAction("pop-group");
        this.$refs.screenNavigation.pushBackAction(() => {
          this.$refs.overview.popScreensGroup();
          this.selectedTile = null;
        }, "pop-group");

        this.$easyscreenStatistic.openWelcomeScreenTile({ title: tile.tileOptions.title });
      },
      _replaceOverviewScreen(tile, options) {
        this.selectedTile = tile;

        this.$refs.overview.replaceScreensGroup({
          id: tile.key + "-" + options.data.nid,
          color: lodash.get(tile, "tileOptions.color"),
          content: {
            data: options.data,
            type: options.type
          }
        });

        this.$refs.screenNavigation.removeBackAction("pop-group");
        this.$refs.screenNavigation.pushBackAction(() => {
          this.$refs.overview.popScreensGroup();
          this.selectedTile = null;
        }, "pop-group");
      },
      _openNodelistElement(tile, element) {
        this.$easyscreenStatistic.openWelcomeScreenNodeList({
          nid: element.nid,
          title: element.title
        });

        this._replaceOverviewScreen(tile, {
          type: "node-list-info",
          data: element
        });
      }
    },
    components: {
      Loader,
      Scrollable,
      EasyscreenVideo,
      ModalLayoutNavigation,
      NodeListTileContent,
      WelcomeScreenOverviewLight,
      welcomeScreenTileLight
    }
  };
</script>
