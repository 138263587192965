import { get, castArray, isObjectLike, cloneDeep } from "lodash";
import { slideConverter, slidesCoverter } from "./presentation-set.js";
import defaultLayout from "./welcome-screen-layouts/default.js";
import bronbibDefaultLayout from "./welcome-screen-layouts/bronbib-default.js";
import guldbibDefaultLayout from "./welcome-screen-layouts/guldbib-default.js";
import middelfartDefaultLayout from "./welcome-screen-layouts/middelfart-default.js";
import middelfartCinemaLayout from "./welcome-screen-layouts/middelfart-cinema.js";
import naesbibDefaultLayout from "./welcome-screen-layouts/naesbib-default.js";

function applyWelcomeScreenModifer(options) {
  options = options || {};

  let welcomeScreenType = "welcome-screen-modifer";
  if (window.ESCONFIG.defaultDesign === "light") {
    welcomeScreenType = "welcome-screen-light-modifer";
  }

  if (options.layout) {
    options.layout = castArray(options.layout).filter(Boolean).concat([welcomeScreenType]);
  }

  options.subType = [options.subType, welcomeScreenType].filter(Boolean).join(" ");
  options.touch = true;

  return options;
}

async function welcomeScreenTileLinks(associatedSlides) {
  if (!Array.isArray(associatedSlides)) {
    return [];
  }

  return await Promise.all((await slidesCoverter(associatedSlides, {})).map(async (slide, index) => {
    return {
      title: (associatedSlides[index].slideData.overrideTitle || associatedSlides[index].slideData.slideName || "").replace(/\/\*.+?\*\//g, ""),
      links: await welcomeScreenTileLinks(get(slide, "data.settings.associatedSlides")),
      slides: {
        ...slide,
        widgets: slide.widgets.filter(widget => {
          return widget.name !== "presentation-set-title" && widget.name !== "presentation-set-switcher";
        }).map(widget => {
          widget.options = applyWelcomeScreenModifer(widget.options);
          if (widget.contentType === "FullScreenCT") {
            widget.options.withLoader = true;
            widget.options.design = window.ESCONFIG.defaultDesign;
          }

          if (widget.getter) {
            const originalGetter = widget.getter;
            widget.getter = async function tileGetter(context) {
              let options = await originalGetter(context);

              return applyWelcomeScreenModifer(options);
            };
          }
          return widget;
        })
      }
    };
  }));
}

async function welcomeScreenTilesWrapper(index, settings, tilesWrapperData) {
  return {
    ...cloneDeep(get(tilesWrapperData, index, {})),
    color: settings.color,
    wayfinder: settings.wayfinderPosition ? {
      view: settings.wayfinderPosition.view_id,
      markId: settings.wayfinderPosition.location_id,
      title: settings.wayfinderLabel
    } : undefined,
    links: get(settings, "associatedSlides.length", 0) !== 0 ?
      (await welcomeScreenTileLinks(settings.associatedSlides)) : undefined
  };
}

function getWelcomeScreenLayout(name, skin) {
  switch (skin) {
    case "bronbib": 
      return cloneDeep(bronbibDefaultLayout);
    case "guldbib":
      return cloneDeep(guldbibDefaultLayout);
    case "middelfart":
      if (name === "cinema") {
        return cloneDeep(middelfartCinemaLayout);
      }

      return cloneDeep(middelfartDefaultLayout);
    case "naesbib":
      return cloneDeep(naesbibDefaultLayout);
  }

  return cloneDeep(defaultLayout);
}

async function welcomeScreenTiles(screenData) {
  if (!isObjectLike(screenData)) {
    return null;
  }

  const tiles = await Promise.all((screenData.content || []).map(async (tile, index, tiles) => {
    let slide = await slideConverter(tile, {}, tiles);
    if (!get(slide, "widgets")) {
      return null;
    }

    slide.widgets = slide.widgets.filter(widget => {
      return widget.name !== "presentation-set-title" && widget.name !== "presentation-set-switcher";
    });

    let tilesWrapperData = getWelcomeScreenLayout(screenData.machineName);

    if (window.ESCONFIG.skin) {
      tilesWrapperData = getWelcomeScreenLayout(screenData.machineName, window.ESCONFIG.skin);
    }

    if (!tilesWrapperData[index]) {
      return null;
    }

    tilesWrapperData = tilesWrapperData.slice().sort((a, b) => {
      const byTop = parseInt(a.top, 10) - parseInt(b.top, 10);
      const byLeft = parseInt(a.left, 10) - parseInt(b.left, 10);

      return byTop !== 0 ? byTop : byLeft;
    });

    return  {
      tileData: get(slide, "widgets[0].welcomeScreenType") ? {
        options: slide.widgets[0].options,
        type: slide.widgets[0].welcomeScreenType,
        getter: slide.widgets[0].getter,
        customBg: slide.data.customBg
      } : null,
      tileOptions: {
        ...(await welcomeScreenTilesWrapper(index, tile.slideData.settings, tilesWrapperData)),
        title: tile.slideData.overrideTitle || tile.slideData.slideName
      }
    };
  }));

  return tiles.filter(Boolean);
}

const languageMap = {
  DK: "denmark",
  EN: "united-kingdom"
};

export default async function(screens) {
  screens = await Promise.all(castArray(screens).map(async (screen) => {
    /* Convert the welcome screen medial type into legacy media format (https://inlead.atlassian.net/browse/ES-2422?focusedCommentId=86216) */
    let welcomeScreenMediaType = screen.welcomeVideo;
    screen.welcomeVideo = null;

    if (welcomeScreenMediaType === "1" || welcomeScreenMediaType === "2") {
      screen.welcomeVideo = screen.welcomeMedia;
    } else if (welcomeScreenMediaType === "3") {
      screen.welcomeImage = screen.welcomeMedia;
    }

    screen.bg = screen.customBgFile;
    screen.tiles = await welcomeScreenTiles(screen);
    let options = {};
    (screen.title.match(/\[[^\]]*\]/gi) || []).forEach(option => {
      option = option.substring(1, option.length - 1).split("=");
      if (option.length === 2) {
        if (option[0] === "index") {
          option[1] = parseInt(option[1], 10);
        } else if (option[0] === "lang") {
          option[1] = option[1].trim();

          option[1] = languageMap[option[1]] || option[1];
          options["icon"] = "/images/flags/" + option[1] + ".png";
        }

        options[option[0]] = option[1];
      }
    });
    screen.options = options;
    return screen;
  }));

  const screensWithIndex = screens.filter(screen => {
    return !!screen.options && screen.options.index !== undefined;
  }).sort((a, b) => {
    return a.options.index - b.options.index;
  });

  const screensWithoutIndex = screens.filter(screen => {
    return (!!screen.options && screen.options.index === undefined) || !screen.options;
  });

  return screensWithIndex.concat(screensWithoutIndex);
}
